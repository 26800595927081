import React, { useRef, useEffect, useCallback } from 'react';
import { StyledGaliaGptContainer, StyledGaliaTooltip } from './styles';
import { useClass } from '@modules/classes/context/ClassContext';

type GaliaGptProps = {
  id: string | number;
  module: string | number;
};

export const GaliaGpt: React.FC<GaliaGptProps> = (props) => {
  const { id, module } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const { classCurrent } = useClass();

  const handleOnClick = useCallback(() => {
    const teachers = classCurrent.teachers?.map((t) => `teacher=${t.id}`) || []
    const students = classCurrent.enrollments?.map((e) => `student=${e.student_id}`) || []
    const params = [...teachers, ...students, `class=${classCurrent.id}`].join('&');
    window.open(`${process.env.REACT_APP_LINK_IA_SECTORS}/gpt/Galia?${params}`, '_blank');
  }, [classCurrent.enrollments, classCurrent.id, classCurrent.teachers]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let initialX = 0;
    let initialY = 0;
    let clickTimeout: NodeJS.Timeout;

    const onMouseDown = (e: MouseEvent) => {
      isDragging = false;
      startX = e.clientX;
      startY = e.clientY;
      initialX = container.offsetLeft;
      initialY = container.offsetTop;

      clickTimeout = setTimeout(() => {
        isDragging = true;
      }, 200);

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    const onMouseMove = (e: MouseEvent) => {
      const dx = e.clientX - startX;
      const dy = e.clientY - startY;

      if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
        isDragging = true;
        clearTimeout(clickTimeout);
      }

      if (!isDragging) return;

      let newX = initialX + dx;
      let newY = initialY + dy;

      const containerRect = container.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (newX < 0) newX = 0;
      if (newY < 0) newY = 0;
      if (newX + containerRect.width > viewportWidth) newX = viewportWidth - containerRect.width;
      if (newY + containerRect.height > viewportHeight) newY = viewportHeight - containerRect.height;

      container.style.left = `${newX}px`;
      container.style.top = `${newY}px`;
    };

    const onMouseUp = (e: MouseEvent) => {
      clearTimeout(clickTimeout);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      if (!isDragging) {
        handleOnClick();
      }

      isDragging = false;
    };

    const onTouchStart = (e: TouchEvent) => {
      isDragging = false;
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
      initialX = container.offsetLeft;
      initialY = container.offsetTop;

      clickTimeout = setTimeout(() => {
        isDragging = true;
      }, 200);

      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);
    };

    const onTouchMove = (e: TouchEvent) => {
      const dx = e.touches[0].clientX - startX;
      const dy = e.touches[0].clientY - startY;

      if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
        isDragging = true;
        clearTimeout(clickTimeout);
      }

      if (!isDragging) return;

      let newX = initialX + dx;
      let newY = initialY + dy;

      const containerRect = container.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (newX < 0) newX = 0;
      if (newY < 0) newY = 0;
      if (newX + containerRect.width > viewportWidth) newX = viewportWidth - containerRect.width;
      if (newY + containerRect.height > viewportHeight) newY = viewportHeight - containerRect.height;

      container.style.left = `${newX}px`;
      container.style.top = `${newY}px`;
    };

    const onTouchEnd = (e: TouchEvent) => {
      clearTimeout(clickTimeout);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);

      if (!isDragging) {
        handleOnClick();
      }

      isDragging = false;
    };

    container.addEventListener('mousedown', onMouseDown);
    container.addEventListener('touchstart', onTouchStart);

    return () => {
      container.removeEventListener('mousedown', onMouseDown);
      container.removeEventListener('touchstart', onTouchStart);
    };
  }, [handleOnClick, id, module]);

  return (
    <StyledGaliaGptContainer ref={containerRef} className="galia-gpt-container">
      <StyledGaliaTooltip className="tooltip">Posso ajudar?</StyledGaliaTooltip>
    </StyledGaliaGptContainer>
  );
};